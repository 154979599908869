import React, { Component } from 'react'


class NotFound extends Component {
    render() {
        return(
            <>
                <h1>404 page not found</h1>
                <h3>try doing something less stupid next time</h3>
            </>
        )
    }
}

export default NotFound;